import { sendGTMEvent as sendGTMEventNext } from "@next/third-parties/google";
import { ServerExtraService } from 'types';

export const prepareExtraServices = (extraServices: { [key: string]: ServerExtraService } | null) =>
  extraServices
    ? Object.values(extraServices)
      .map((item) => ({
        ...item,
        amount: item.count,
      }))
      .filter(({ amount }) => !!amount)
    : null;

export const isProd = () => process.env.NODE_ENV === 'production';

export const sendGTMEvent = (name: string, data?: { [key: string]: string | number | boolean }) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];

    console.warn(`Pushing event ${name} to GTM`, data);

    if (typeof sendGTMEvent === "function") {
      sendGTMEventNext({ event: name, ...data });
    } else {
      console.warn(
        "sendGTMEvent function is not available. Pushing directly to dataLayer."
      );

      window.dataLayer.push({
        event: name,
        ...data,
      });
    }
  }
}